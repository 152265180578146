<template>
  <div class="profile__tabs-wrapper">
    <tabs v-model="tab" :tabs="tabs" @click="$emit('click', $event)" />
  </div>
</template>

<script>
import Tabs from "@/components/ui/tabs";
import { UI_PROFILE_TABS } from "@/constants";

export default {
  components: { Tabs },

  props: {
    tabs: { type: String, required: false, default: UI_PROFILE_TABS },
    value: { type: String, required: true },
  },

  computed: {
    tab: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
