<template>
  <div class="tabs">
    <div
      class="tabs__item"
      v-for="tab in tabList"
      :key="tab.value"
      :class="{ tabs__item_active: tab.value === value }"
      @click="setValue(tab.value)"
    >
      <span class="tabs__link">{{ tab.title }}</span>
    </div>
    <div class="tabs__border" :style="borderStyle" />
  </div>
</template>

<script>
export default {
  props: {
    /**
     * tabs Array have to include
     * objects like that: { value, title }
     *
     * tabs Object have to look like that: { [value]: title }
     */
    tabs: { type: [Array, Object], required: true },
    value: { type: String, required: true },
  },

  computed: {
    tabList() {
      const { tabs } = this;
      if (Array.isArray(tabs)) return tabs;
      return Object.entries(tabs).map(([value, title]) => ({ value, title }));
    },

    activeIndex() {
      const { tabList, value } = this;
      return tabList.findIndex((tab) => tab.value === value);
    },

    borderWidth() {
      return parseFloat((100 / this.tabList.length).toFixed(2), 10);
    },

    borderPositionLeft() {
      const { borderWidth, activeIndex } = this;
      return borderWidth * activeIndex;
    },

    borderStyle() {
      const { borderPositionLeft, borderWidth } = this;
      return { left: `${borderPositionLeft}%`, width: `${borderWidth}%` };
    },
  },

  methods: {
    setValue(value) {
      this.$emit("input", value);
      this.$emit("click", value);
    },
  },
};
</script>
