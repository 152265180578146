<template>
  <v-popover
    :trigger="trigger"
    class="tooltip__popover"
    popoverBaseClass="tooltip"
    popoverInnerClass="tooltip__inner"
    popoverArrowClass="tooltip__arrow"
    boundariesElement="window"
    openClass="tooltip_open"
    :placement="placement"
    :offset="offset"
    :delay="{ show: delay }"
    @show="show()"
    @hide="hide()"
    ref="tooltip"
  >
    <span class="tooltip__trigger">
      <slot name="icon">
        <span class="icon icon__question tooltip__question" />
      </slot>
    </span>

    <template slot="popover">
      <slot />
    </template>
  </v-popover>
</template>
<script>
import { VPopover } from "v-tooltip";
import { isMobile } from "@/utils/system";

export default {
  components: { VPopover },

  props: {
    delay: { type: Number, default: 200 },
    placement: { type: String, default: "bottom" },
    offset: { type: Number, default: 15 },
    trigger: {
      type: String,
      default: isMobile ? "click" : "hover",
      validator(value) {
        return ["hover", "click"].indexOf(value) !== -1;
      },
    },
  },

  methods: {
    show() {
      this.$refs.tooltip.$refs.popover.classList.add("tooltip_block");
    },
    hide() {
      this.$refs.tooltip.$refs.popover.classList.remove("tooltip_block");
    },
  },
};
</script>
